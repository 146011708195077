export class GrzHelper {
  private static a = 'аaвbеeкkмmнhоoрpсcтtуyхxАAВBЕEКKМMНHОOРPСCТTУYХX';

  public static testRus(str: string) {
    const grzWithoutSpaces = str.replace(/\s/g, '');
    const regExpTest = new RegExp(`^[${GrzHelper.a}][0-9]{3}[${GrzHelper.a}]{2}[0-9]{2,3}$`, 'g');
    return regExpTest.test(grzWithoutSpaces);
  }

  public static testForeign(str: string) {
    const grzWithoutSpaces = str.replace(/\s/g, '');
    const regExpTest = new RegExp(`^[A-ZА-Я0-9]{7,10}$`, 'giu');
    return regExpTest.test(grzWithoutSpaces);
  }

  public static clearRus(str: string) {
    const regExpClear = new RegExp(`[^0-9${GrzHelper.a}]`, 'g');
    return str.replace(regExpClear, '');
  }
}
